'use client';
import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';
import GmapsIcon from '~/icons/gmaps.svg';
import OtzovikIcon from '~/icons/otzovik.svg';
import YmapsIcon from '~/icons/ymaps.svg';
import IIcon from '~/icons/i_icons.svg';
import StarIcon from '~/icons/star_3.svg';

interface Props {
  className?: string;
}

export const BonusProgramSection: FC<Props> = ({
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  return (
    <Container
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
      variant={'large'}
    >
      <Container
        className={
          'px-0 sm:px-3.2 md:px-4.2 lg:px-5.6'
        }
        variant={'small'}
      >
        <Title
          as={'h2'}
          trigger={inView}
          className={cvaHeading()}
        >
          Бонусная программа
        </Title>
      </Container>

      <div className={cvaList()}>
        <AnimatedTranslateWrapper
          className={cvaItem({ size: 'small' })}
          initial={{ y: '1.2rem' }}
          transition={{ delay: 0.4 }}
          trigger={inView}
        >
          <AnimatedTranslateWrapper
            initial={{ y: '2.4rem' }}
            transition={{ delay: 0.4 }}
            trigger={inView}
          >
            <div className={cvaItemHeader()}>
              <div className={cvaItemHeading()}>
                %
              </div>
            </div>
            <div className={cvaItemDescr()}>
              Кэшбэк с каждой покупки
            </div>
            <div className={cvaSeparator()} />
          </AnimatedTranslateWrapper>
        </AnimatedTranslateWrapper>

        <AnimatedTranslateWrapper
          className={cvaItem({ size: 'large' })}
          initial={{ y: '1.2rem' }}
          transition={{ delay: 0.5 }}
          trigger={inView}
        >
          <AnimatedTranslateWrapper
            initial={{ y: '2.4rem' }}
            transition={{ delay: 0.5 }}
            trigger={inView}
          >
            <div className={cvaItemHeader()}>
              <div className={cvaItemHeading()}>
                309
              </div>
              <div className={cvaItemIcons()}>
                <div className="w-fit h-fit relative">
                  <StarIcon className="w-4 h-4 sm:w-7.2 sm:h-7.2" />
                  <IIcon className="w-2.2 h-2.2 sm:w-4 sm:h-4 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
                <OtzovikIcon
                  className={cvaItemIcon()}
                />
              </div>
            </div>
            <div className={cvaItemDescr()}>
              Бонусов за публикацию на 
              <Link
                className={cvaItemDescrLink()}
                href={'https://irecommend.ru/'}
                hoverEffect={'color'}
              >
                Irecommend
              </Link>
                и на сайте{' '}
              <Link
                className={cvaItemDescrLink()}
                href={'https://otzovik.com/'}
                hoverEffect={'color'}
              >
                Отзовик
              </Link>
            </div>
            <Link
              className={cvaMoreLink()}
              contentWrapperClassName={cvaMoreLinkContent()}
              prefetch={false}
              href={
                process.env
                  .NEXT_PUBLIC_FRONT_BASE_URL +
                '/bonus/'
              }
              hoverEffect={'color'}
            >
              Подробнее
            </Link>
            <div
              className={cvaSeparator({
                hideTablet: true,
              })}
            />
          </AnimatedTranslateWrapper>
        </AnimatedTranslateWrapper>

        <AnimatedTranslateWrapper
          className={cvaItem({ size: 'small' })}
          initial={{ y: '1.2rem' }}
          transition={{ delay: 0.6 }}
          trigger={inView}
        >
          <AnimatedTranslateWrapper
            initial={{ y: '2.4rem' }}
            transition={{ delay: 0.6 }}
            trigger={inView}
          >
            <div className={cvaItemHeader()}>
              <div className={cvaItemHeading()}>
                -10%
              </div>
            </div>
            <div className={cvaItemDescr()}>
              В честь вашего Дня Рождения
            </div>
            <Link
              className={cvaMoreLink({
                addOffset: true,
              })}
              contentWrapperClassName={cvaMoreLinkContent()}
              prefetch={false}
              href={
                process.env
                  .NEXT_PUBLIC_FRONT_BASE_URL +
                '/bonus/'
              }
              hoverEffect={'color'}
            >
              Подробнее
            </Link>
            <div className={cvaSeparator()} />
          </AnimatedTranslateWrapper>
        </AnimatedTranslateWrapper>

        <AnimatedTranslateWrapper
          className={cvaItem({ size: 'large' })}
          initial={{ y: '1.2rem' }}
          transition={{ delay: 0.7 }}
          trigger={inView}
        >
          <AnimatedTranslateWrapper
            initial={{ y: '2.4rem' }}
            transition={{ delay: 0.7 }}
            trigger={inView}
          >
            <div className={cvaItemHeader()}>
              <div className={cvaItemHeading()}>
                309
              </div>
              <div className={cvaItemIcons()}>
                <YmapsIcon
                  className={cvaItemIcon()}
                />
                <GmapsIcon
                  className={cvaItemIcon()}
                />
              </div>
            </div>
            <div className={cvaItemDescr()}>
              Бонусов за публикацию отзыва <br />
              на{' '}
              <Link
                className={cvaItemDescrLink()}
                href={'https://yandex.ru/maps/'}
                hoverEffect={'color'}
              >
                Яндекс.Картах
              </Link>
              {' '}и{' '}
              <Link
                className={cvaItemDescrLink()}
                href={
                  'https://www.google.com/maps/'
                }
                hoverEffect={'color'}
              >
                Google.Картах
              </Link>
            </div>
            <Link
              className={cvaMoreLink()}
              contentWrapperClassName={cvaMoreLinkContent()}
              prefetch={false}
              href={
                process.env
                  .NEXT_PUBLIC_FRONT_BASE_URL +
                '/bonus/'
              }
              hoverEffect={'color'}
            >
              Подробнее
            </Link>
          </AnimatedTranslateWrapper>
        </AnimatedTranslateWrapper>
      </div>

      <Link
        href={
          process.env.NEXT_PUBLIC_FRONT_BASE_URL +
          '/bonus/'
        }
        prefetch={false}
        className={cvaLink()}
        contentWrapperClassName={
          'flex justify-center items-center gap-[0.45rem] pl-0.9'
        }
        rootWrapperClassName="justify-center"
        size={'big'}
        color={'outlinedOnWhite'}
        hoverEffect={'highlight'}
      >
        Подробнее
        <ArrowRightIcon
          className={
            'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.3'
          }
        />
      </Link>
    </Container>
  );
};

const cvaRoot = cva([
  'BonusProgramSection-cvaRoot',
  'flex flex-col',
  'my-6.4 sm:my-[10.4rem]',
]);

const cvaHeading = cva([
  'BonusProgramSection-cvaHeading',
  'font-secondary text-h2',
]);

const cvaList = cva([
  'BonusProgramSection-cvaList',
  // 'flex flex-wrap gap-0.8 sm:gap-1.2',
  'grid grid-cols-2 lg:flex lg:flex-wrap gap-0.8 sm:gap-1.2 w-fit',
  'mt-2.4 sm:mt-4.8',
]);

const cvaItem = cva(
  [
    'BonusProgramSection-cvaItem',
    'relative',
    'p-2 sm:p-3.2',
    'rounded-[2rem] sm:rounded-[3.2rem]',
    'text-bodyM',
    'bg-cLightGrey',
    'child-1:order-1 lg:child-1:order-1',
    'child-2:col-span-2 sm:child-2:col-span-1 child-2:order-3 lg:child-2:order-2',
    'child-3:order-2 lg:child-3:order-3',
    'child-4:col-span-2 sm:child-4:col-span-1 child-4:order-4 lg:child-4:order-4',
    'min-h-[17.8rem] sm:min-h-[auto]',
  ],
  {
    variants: {
      size: {
        small: 'flex-1 lg:max-w-[25.1rem]',
        large: 'flex-1 basis-full lg:basis-0',
      },
    },
  }
);

const cvaSeparator = cva(
  [
    'BonusProgramSection-cvaSeparator',
    'absolute -bottom-0.8 sm:-bottom-1.2 right-1/2 translate-x-1/2 lg:bottom-auto lg:top-1/2 lg:-right-1.2 lg:-translate-y-1/2 lg:translate-x-0',
    'w-full h-0.8 sm:h-1.2 lg:w-1.2 lg:h-full',
    'bg-cLightGrey',
    'before:absolute before:top-0 before:left-0 before:bg-cWhite before:w-[calc(50%-0.8rem)] before:h-full before:rounded-r-[2rem] sm:before:w-[calc(50%-1.2rem)] sm:before:rounded-r-[3.2rem] lg:before:w-full lg:before:h-[calc(50%-1.2rem)] lg:before:rounded-b-[3.2rem]',
    'after:absolute after:bottom-0 after:right-0 after:bg-cWhite after:w-[calc(50%-0.8rem)] after:h-full after:rounded-l-[2rem] sm:after:w-[calc(50%-1.2rem)] sm:after:rounded-l-[3.2rem] lg:after:left-0 lg:after:w-full lg:after:h-[calc(50%-1.2rem)] lg:after:rounded-l-none lg:after:rounded-t-[3.2rem]',
  ],
  {
    variants: {
      hideTablet: {
        true: 'sm:hidden lg:block',
        false: '',
      },
    },
    defaultVariants: {
      hideTablet: false,
    },
  }
);

const cvaItemHeader = cva([
  'BonusProgramSection-cvaItemHeader',
  'relative',
]);

const cvaItemHeading = cva([
  'BonusProgramSection-cvaItemHeading',
  'font-secondary text-h2 text-cMediumGreen',
]);

const cvaItemIcons = cva([
  'BonusProgramSection-cvaItemIcons',
  'absolute -top-0.7 sm:-top-1.2 -right-0.4 sm:right-0',
  'flex gap-0.4 sm:gap-0.8',
]);

const cvaItemIcon = cva([
  'BonusProgramSection-cvaItemIcon',
  'relative',
  'w-4 h-4 sm:w-7.2 sm:h-7.2',
]);

const cvaItemDescr = cva([
  'BonusProgramSection-cvaItemDescr',
  'mt-3.2 sm:mt-4.8',
  'text-bodyM',
]);

const cvaItemDescrLink = cva([
  'BonusProgramSection-cvaItemDescrLink',
  'inline-flex',
  'text-cMediumGreen hover:text-cDarkGreen',
]);

const cvaMoreLink = cva(
  [
    'BonusProgramSection-cvaMoreLink',
    'inline-block group',
    'mt-1.2 sm:mt-1.6',
    'underline underline-offset-4',
  ],
  {
    variants: {
      addOffset: {
        true: 'mt-1.6',
        false: '',
      },
    },
    defaultVariants: {
      addOffset: false,
    },
  }
);

const cvaMoreLinkContent = cva([
  'BonusProgramSection-cvaMoreLinkContent',
  // 'text-bodyMSemibold underline underline-offset-4 decoration-cBlack32 group-hover:decoration-cMediumGreen/30 transition-all duration-300',
]);

const cvaLink = cva([
  'BonusProgramSection-cvaLink',
  'mx-auto mt-2.4 sm:mt-4.8',
  'text-bodyLSemibold',
]);
