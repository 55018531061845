'use client';
import { ReviewPreviewCard } from '@/entities/review';
import { IReview } from '@/shared/api/getReviews/types';
import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { MasonryGrid } from '@/widgets/reviews-preview-section/ui/MasonryGrid';
import { YandexReviewsLink } from '@/widgets/reviews-preview-section/ui/YandexReviewsLink';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import {
  ComponentProps,
  FC,
  useRef,
} from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  reviews: IReview[];
}

export const ReviewsPreviewSection: FC<Props> = ({
  className,
  reviews,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });
  const cutReviews = reviews.slice(0, 10);

  return (
    <div
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
    >
      <YandexReviewsLink />

      <Container
        className={cvaContainer()}
        variant={'large'}
      >
        <div className={cvaHeadingContainer()}>
          <Title
            as={'h2'}
            trigger={inView}
            className={cvaHeading()}
          >
               Более 4000{' '}
            <div className={'text-cBlack'}>
              клиентов
            </div>
          </Title>

          <AnimatedTranslateWrapper
            trigger={inView}
            initial={{ y: '1.2rem' }}
            transition={{ delay: 0.4 }}
            as={'div'}
          >
            <div className={cvaDescr()}>
              Помогаем ухаживать за собой <br />{' '}
              комфортно и&nbsp;легко.
            </div>
          </AnimatedTranslateWrapper>
        </div>

        <MasonryGrid>
          {cutReviews.map((i, idx) => (
            <ReviewPreviewCard
              key={i.id}
              data={i}
              variant={
                // i.variant as ComponentProps<
                //   typeof ReviewPreviewCard
                // >['variant']
                i.photos.length ? 'image' : 'text'
              }
              index={idx}
              bgImageVariant={
                (idx + 1) as ComponentProps<
                  typeof ReviewPreviewCard
                >['bgImageVariant']
              }
            />
          ))}
        </MasonryGrid>

        <Link
          href={
            process.env
              .NEXT_PUBLIC_FRONT_BASE_URL +
            '/instagram/'
          }
          prefetch={false}
          className={cvaLink()}
          rootWrapperClassName="flex justify-center items-center"
          contentWrapperClassName={
            'flex justify-center items-center gap-[0.45rem] pl-0.9'
          }
          color={'outlinedOnWhite'}
          size={'big'}
          hoverEffect={'highlight'}
        >
          Все отзывы
          <ArrowRightIcon
            className={
              'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.3'
            }
          />
        </Link>
      </Container>
    </div>
  );
};

const cvaRoot = cva([
  'ReviewsPreviewSection-cvaRoot',
  'relative',
  'overflow-hidden',
  'my-6.4 sm:my-[10.4rem] pb-0.2',
]);

const cvaContainer = cva([
  'ReviewsPreviewSection-cvaContainer',
  'flex flex-col',
  'mt-3.2 sm:mt-0',
]);

const cvaHeadingContainer = cva([
  'ReviewsPreviewSection-cvaHeadingContainer',
  'max-w-[80rem]',
  'mx-auto',
]);

const cvaHeading = cva([
  'ReviewsPreviewSection-cvaHeading',
  'font-secondary text-h1 text-center text-cMediumGreen',
]);

const cvaDescr = cva([
  'ReviewsPreviewSection-cvaDescr',
  'max-w-[40.6rem]',
  'mx-auto mt-2.4 sm:mt-4.8',
  'text-bodyL text-cBlack80 text-center',
]);

const cvaLink = cva([
  'ReviewsPreviewSection-cvaLink',
  'mx-auto mt-2.2 sm:mt-4.8',
  'text-bodyLSemibold',
]);
