'use client';
import { useMediaValues } from '@/shared/hooks/useMediaValues';
import { Link } from '@/shared/ui/Link';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import {
  motion,
  MotionValue,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import {
  Children,
  FC,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import useMeasure from 'react-use-measure';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  sliderClassName?: string;
  sliderWrapperClassName?: string;
  children?: ReactNode;
  scrollYProgress?: MotionValue<number>;
}

export const RightSlider: FC<Props> = ({
  className,
  sliderClassName,
  sliderWrapperClassName,
  scrollYProgress,
  children,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [sliderMeasureRef, sliderMeasure] =
    useMeasure();
  const [sliderWrapperRef, sliderWrapperMeasure] =
    useMeasure();
  const sliderProgressWrapperRef =
    useRef<HTMLDivElement>(null);
  const reserveScrollYProgress =
    useMotionValue(0);

  const [swiper, setSwiper] =
    useState<SwiperType | null>(null);

  const yOutputRange = useMediaValues(
    {
      0: [0, 0],
      1024: [
        0,
        sliderMeasure.height -
          sliderWrapperMeasure.height,
      ],
    },
    0,
    [
      sliderMeasure.height,
      sliderWrapperMeasure.height,
    ]
  );

  const y: MotionValue<number> = useTransform(
    scrollYProgress ?? reserveScrollYProgress,
    [0, 1],
    yOutputRange
  );

  const cols = useMediaValues(
    {
      0: 1,
      1440: 2,
    },
    0
  );

  const [activeSlideIndex, setActiveSlideIndex] =
    useState(0);

  // const sliderProgressWrapperY = useTransform(
  //   y,
  //   [
  //     0,
  //     sliderMeasure.height +
  //       76 -
  //       sliderWrapperMeasure.height,
  //     sliderMeasure.height -
  //       sliderWrapperMeasure.height,
  //   ],
  //   [0, 0, -76]
  // );

  const sliderProgressWrapperBg = useTransform(
    y,
    [
      0,
      sliderMeasure.height +
        138 -
        sliderWrapperMeasure.height,
      sliderMeasure.height -
        sliderWrapperMeasure.height,
    ],
    useMediaValues(
      {
        0: ['#F8F8FD', '#F8F8FD', '#F8F8FD'],
        1024: ['#F2F2FC', '#F2F2FC', '#F8F8FD'],
      },
      1024
    )
  );

  return (
    <div
      className={twMergeExt(cvaRoot(), className)}
    >
      <div className={'md:hidden'}>
        <Swiper
          className={twMergeExt(
            cvaSlider(),
            sliderClassName
          )}
          wrapperClass={twMergeExt(
            'swiper-wrapper',
            sliderWrapperClassName
          )}
          slidesPerView={'auto'}
          spaceBetween={12}
          slidesOffsetBefore={32}
          slidesOffsetAfter={32}
          breakpoints={{
            0: {
              spaceBetween: 8,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            768: {
              spaceBetween: 12,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
            },
          }}
          onSwiper={setSwiper}
        >
          {Children.map(children, (child) => (
            <SwiperSlide
              className={'!w-min h-auto'}
            >
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={
            'flex justify-center mt-2 sm:mt-3.2'
          }
        >
          <SliderProgress swiper={swiper} />
        </div>
        <div
          className={
            'sm:flex h-0 justify-center md:h-auto lg:col-span-2 opacity-0 '
          }
        >
          <Link
            className={cvaLink()}
            contentWrapperClassName={
              'flex justify-center items-center gap-[0.45rem] pl-0.9'
            }
            color={'outlined'}
            size={'big'}
            hoverEffect={'roundLight'}
            href={'/products'}
          >
            В каталог
            <ArrowRightIcon
              className={
                'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.5'
              }
            />
          </Link>
        </div>
      </div>

      <div
        ref={mergeRefs([
          sliderRef,
          sliderMeasureRef,
        ])}
        className={twMergeExt(
          cvaSlider(),
          'hidden md:grid',
          sliderClassName
        )}
      >
        <motion.div
          ref={sliderWrapperRef}
          className={twMergeExt(
            cvaSliderWrapper(),
            sliderWrapperClassName
          )}
          style={{ y }}
        >
          {Children.map(
            children,
            (child, index) => (
              <motion.div
                onViewportEnter={() =>
                  setActiveSlideIndex(index)
                }
                viewport={{
                  root: sliderRef,
                  amount: 'all',
                }}
              >
                {child}
              </motion.div>
            )
          )}

          <div
            className={
              'flex justify-center h-auto lg:col-span-2 opacity-0'
            }
          >
            <Link
              className={cvaLink()}
              contentWrapperClassName={
                'flex justify-center items-center gap-[0.45rem] pl-0.9'
              }
              color={'outlinedOnGrey'}
              size={'big'}
              hoverEffect={'highlight'}
              href={'/products'}
            >
              В каталог
              <ArrowRightIcon
                className={
                  'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.5'
                }
              />
            </Link>
          </div>
        </motion.div>

        <motion.div
          ref={sliderProgressWrapperRef}
          className={cvaSliderProgressWrapper()}
          style={{
            x: '-50%',
            // y: sliderProgressWrapperY,
            backgroundColor:
              sliderProgressWrapperBg,
          }}
        >
          <SliderProgress
            forceCurrentSlide={Math.ceil(
              (activeSlideIndex + 1) / cols
            )}
            forceTotal={Math.ceil(
              Children.toArray(children).length /
                cols
            )}
          />
        </motion.div>
      </div>
    </div>
  );
};

const cvaRoot = cva([
  'RightSlider-cvaRoot',
  'top-0 left-0 md:absolute',
  'w-full h-full',
  'pt-2 pb-4 sm:py-3.2 md:p-3.2 max-[768px]:pb-1.4',
]);

const cvaSlider = cva([
  'RightSlider-cvaSlider',
  'relative',
  'overflow-hidden md:h-full',
  'md:rounded-[1.6rem]',
]);

const cvaSliderWrapper = cva([
  'RightSlider-cvaSliderWrapper',
  'left-0 top-0 md:absolute',
  'grid grid-cols-1 gap-1.2 lg:grid-cols-2',
  'w-full',
]);

const cvaSliderProgressWrapper = cva([
  'RightSlider-cvaSliderProgressWrapper',
  'absolute z-[1] bottom-[11.6rem] left-1/2 md:bottom-2.4',
  '!w-min',
  'rounded-[1.6rem] md:px-1.6 md:py-1.2',
]);

const cvaLink = cva([
  'RightSlider-cvaLink',
  'mt-2 sm:mt-3.2 md:mt-8.8 md:mb-0.4',
  'text-bodyLSemibold',
]);
