'use client';
import { SliderNavigationButton } from '@/shared/ui/SliderNavigationButton';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import React, {
  Children,
  FC,
  ReactNode,
  useState,
} from 'react';
import { EffectFade } from 'swiper/modules';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

interface Props {
  className?: string;
  controlsClassName?: string;
  children?: ReactNode;
  onSlideChange: (realIndex: number) => void;
}

export const LeftSlider: FC<Props> = ({
  children,
  className,
  controlsClassName,
  onSlideChange,
}) => {
  const [swiper, setSwiper] =
    useState<SwiperType | null>(null);

  return (
    <div
      className={twMergeExt(cvaRoot(), className)}
    >
      <div
        className={twMergeExt(
          cvaControls(),
          controlsClassName
        )}
      >
        <SliderProgress swiper={swiper} />
        <div className={'flex gap-0.6'}>
          <SliderNavigationButton
            className={cvaSliderNavigationButton()}
            direction={'prev'}
            swiper={swiper}
            color={'outlinedOnGrey'}
          />
          <SliderNavigationButton
            className={cvaSliderNavigationButton()}
            direction={'next'}
            swiper={swiper}
            color={'outlinedOnGrey'}
          />
        </div>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={12}
        modules={[EffectFade]}
        effect={'fade'}
        fadeEffect={{ crossFade: true }}
        onSlideChange={(swiper) =>
          onSlideChange(swiper.realIndex)
        }
        onSwiper={setSwiper}
      >
        {Children.map(children, (child) => {
          return (
            <SwiperSlide>
              {({ isActive }) => {
                return (
                  <motion.div
                    initial={{
                      opacity: 1,
                      scale: 1,
                    }}
                    animate={{
                      opacity: isActive ? 1 : 1,
                      scale: isActive ? 1 : 1,
                    }}
                  >
                    {React.isValidElement(child)
                      ? React.cloneElement(
                          child,
                          { isActive }
                        )
                      : null}
                  </motion.div>
                );
              }}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const cvaRoot = cva([
  'LeftSlider-cvaRoot',
  'flex-1',
  'pt-0 pb-4 sm:py-3.2',
]);

const cvaControls = cva([
  'LeftSlider-cvaControls',
  'absolute z-[2] top-1.6 sm:top-3.2 right-0',
  'flex items-center gap-3.2',
  'px-1.6 sm:px-3.2',
  'bg-cLightGrey',
]);

const cvaSliderNavigationButton = cva([
  'LeftSlider-cvaSliderNavigationButton',
  ' bg-transparent',
]);
