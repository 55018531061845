'use client';
import { ISet } from '@/shared/api/getMainPage/types';
import { useExpandedArray } from '@/shared/hooks/useExpandedArray';
import { useMediaValues } from '@/shared/hooks/useMediaValues';
import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { CountBadge } from '@/shared/ui/CountBadge';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';

interface Props {
  className?: string;
  items: ISet[];
}

export const CollectionsSection: FC<Props> = ({
  className,
  items,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  const inititalVisibleCount = useMediaValues(
    {
      0: 7,
      768: 13,
    },
    0
  );

  const {
    visibleItems,
    moreCount,
    onExpand,
    isExpanded,
  } = useExpandedArray(
    items,
    inititalVisibleCount
  );

  return (
    <Container
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
      variant={'small'}
    >
      <Title
        as={'h2'}
        className={cvaHeading()}
        trigger={inView}
      >
        Подборки
      </Title>

      <div className={cvaList()}>
        {visibleItems.map((i, index) => (
          <AnimatedTranslateWrapper
            key={index}
            trigger={inView}
            transition={{
              delay: isExpanded
                ? 0.05 * (index - 12)
                : 0.05 * index,
              duration: 0.3,
            }}
          >
            <Link
              className={cvaLink()}
              color={'mediumGreyOnWhite'}
              size={'small'}
              hoverEffect={'highlight'}
              href={`/filters/${i.code}`}
            >
              {i.name}
            </Link>
          </AnimatedTranslateWrapper>
        ))}

        {moreCount > 0 ? (
          <AnimatedTranslateWrapper
            trigger={inView}
            transition={{
              delay: 0.05 * 13,
              duration: 0.3,
            }}
          >
            <Button
              className={cvaLink({ more: true })}
              color={'white'}
              size={'small'}
              hoverEffect={'highlight'}
              onClick={onExpand}
              badgeSlot={
                <CountBadge
                  className={cvaMoreBadge()}
                >
                  {moreCount}
                </CountBadge>
              }
            >
              Ещё
            </Button>
          </AnimatedTranslateWrapper>
        ) : null}
      </div>
    </Container>
  );
};

const cvaRoot = cva([
  'CollectionsSection-cvaRoot',
  'my-6.4 sm:my-[10.4rem]',
]);

const cvaHeading = cva([
  'CollectionsSection-cvaHeading',
  'font-secondary text-h2',
]);

const cvaList = cva([
  'CollectionsSection-cvaList',
  'flex flex-wrap gap-0.8',
  'mt-2.4 sm:mt-4.8',
]);

const cvaLink = cva(
  [
    'CollectionsSection-cvaLink',
    'relative',
    'px-1.6 pb-0.2',
    'text-bodyM',
    'transition-colors duration-300 ease-out hoctive:bg-cLightGreenBtn',
  ],
  {
    variants: {
      more: {
        true: 'group pr-2.4 sm:pr-2 border-[1px] border-cBlack32',
      },
    },
  }
);

const cvaMoreBadge = cva([
  'CollectionsSection-cvaMoreBadge',
  'absolute top-[0.4rem] sm:top-0.9 right-1.1 sm:right-0.6',
  'text-bodyXXS',
  'outline-cWhite group-hover:outline-cLightGreenBtn group-active:outline-cLightGreenBtn',
]);
