import { IReview } from '@/shared/api/getReviews/types';
import { Button } from '@/shared/ui/Button';
import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import BgVariant1 from '../assets/bg-variant-1.svg';
import BgVariant2 from '../assets/bg-variant-2.svg';
import BgVariant3 from '../assets/bg-variant-3.svg';
import BgVariant4 from '../assets/bg-variant-4.svg';
import BgVariant5 from '../assets/bg-variant-5.svg';

interface Props {
  className?: string;
  variant: 'image' | 'text';
  bgImageVariant?: 2 | 4 | 6 | 8 | 10;
  data: IReview;
  index?: number;
}

export const ReviewPreviewCard: FC<Props> = ({
  className,
  variant,
  bgImageVariant,
  data,
  index,
}) => {
  return (
    <div
      className={twMergeExt(
        cvaRoot({
          variant: variant,
          hideTablet: index === 9,
        }),
        className
      )}
    >
      <div className={cvaAuthorContainer()}>
        <div className={cvaAuthorPhotoWrapper()}>
          {data.preview_picture ? (
            <LazyImage
              className={
                'object-center object-cover'
              }
              src={data.preview_picture}
              alt={data.user_name}
              fill
            />
          ) : (
            <LazyImage
              className={
                'object-center object-cover'
              }
              src={'/icons/avatar_empty.svg'}
              alt={data.user_name}
              fill
            />
          )}
        </div>
        <div className={cvaAuthorName()}>
          {data.user_name}
        </div>
      </div>

      {variant === 'image' &&
      data.photos?.length ? (
        <LazyImage
          wrapClassName={cvaPreviewImage()}
          className={'object-center object-cover'}
          src={data.photos[0]}
          alt={''}
          fill
        />
      ) : null}

      {variant === 'text' && data.detail_text ? (
        <>
          {
            {
              2: (
                <BgVariant1
                  className={cvaBgImage({
                    variant: 2,
                  })}
                />
              ),
              4: (
                <BgVariant2
                  className={cvaBgImage({
                    variant: 4,
                  })}
                />
              ),
              6: (
                <BgVariant3
                  className={cvaBgImage({
                    variant: 6,
                  })}
                />
              ),
              8: (
                <BgVariant4
                  className={cvaBgImage({
                    variant: 8,
                  })}
                />
              ),
              10: (
                <BgVariant5
                  className={cvaBgImage({
                    variant: 10,
                  })}
                />
              ),
            }[bgImageVariant ?? 2]
          }

          <div
            className={cvaPreviewTextContainer()}
          >
            <div
              className={cvaPreviewTextQuote()}
            >
              “
            </div>
            <div className={cvaPreviewText()}>
              {data.detail_text}
            </div>
          </div>
        </>
      ) : null}

      <div className={cvaOverlay()}>
        <Button className={cvaButton()}>
          Читать отзыв
        </Button>
      </div>
    </div>
  );
};

const cvaRoot = cva(
  [
    'ReviewPreviewCard-cvaRoot group/ReviewPreviewCard',
    'relative',
    'flex flex-col',
    'overflow-hidden',
    'min-h-[21.2rem] sm:min-h-[35.6rem] rounded-[2rem] sm:rounded-[3.2rem]',
    'bg-cLightGrey',
    'child-1:order-1 sm:child-1:order-1',
    'child-2:order-2 sm:child-2:order-2',
    'child-3:order-4 sm:child-3:order-3',
    'child-4:order-3 sm:child-4:order-4',
    'child-5:order-5 sm:child-5:order-5',
    'child-6:order-6 sm:child-6:order-6',
    'child-7:order-8 sm:child-7:order-7',
    'child-8:order-7 sm:child-8:order-8',
    'child-9:order-9 sm:child-9:order-9',
    'last:order-10 sm:last:order-9',
  ],
  {
    variants: {
      variant: {
        image: 'pt-1.2 sm:pt-1.6',
        text: 'py-1.6',
      },
      hideTablet: {
        true: ['sm:hidden xl:flex'],
      },
    },
  }
);

const cvaAuthorContainer = cva([
  'ReviewPreviewCard-cvaAuthorContainer',
  'relative z-[2]',
  'flex items-center gap-0.8 sm:gap-1.2',
  'px-1.6 pb-1.2 sm:pb-1.6',
]);

const cvaAuthorPhotoWrapper = cva([
  'ReviewPreviewCard-cvaAuthorPhotoWrapper',
  'relative',
  'shrink-0 grow-0 w-2.4 h-2.4 overflow-hidden sm:w-4.8 sm:h-4.8',
  'rounded-full',
]);

const cvaAuthorName = cva([
  'ReviewPreviewCard-cvaAuthorName',
  ' text-bodyMSemibold truncate',
]);

const cvaPreviewImage = cva([
  'ReviewPreviewCard-cvaPreviewImage',
  'relative z-[2]',
  'aspect-[152/164] sm:aspect-[269/276] w-full overflow-hidden',
]);

const cvaPreviewTextContainer = cva(
  [
    'ReviewPreviewCard-cvaPreviewTextContainer',
    'relative z-[2]',
    'mt-auto sm:mt-8 pl-1.6 pr-1.6 sm:pr-3.2',
  ],
  {
    variants: {
      variant: {
        image: 'pt-1.2 sm:pt-1.6',
        text: 'pb-2.4 sm:pb-0',
      },
    },
  }
);

const cvaPreviewTextQuote = cva([
  'ReviewPreviewCard-cvaPreviewTextQuote',
  'font-secondary text-h1',
]);

const cvaPreviewText = cva([
  'ReviewPreviewCard-cvaPreviewText',
  '-mt-0.8 sm:mt-0.8',
  'text-bodyM text-cBlack80',
]);

const cvaBgImage = cva(
  [
    'ReviewPreviewCard-cvaBgImage',
    ' absolute z-[1]',
  ],
  {
    variants: {
      variant: {
        2: 'aspect-[376/382] w-[29.87rem] -top-[13.966rem] -left-[12.727rem] -rotate-[117.98deg] sm:w-[37.6rem] sm:-top-[15.05rem] sm:-left-[12.235rem] sm:-rotate-[133.02deg]',
        4: 'aspect-[303/245] w-[19.712rem] top-[6.637rem] -right-[4.455rem] -rotate-[45deg] sm:w-[30.3rem] sm:top-[8.806rem] sm:-right-[7.515rem] sm:-rotate-[45deg]',
        6: 'aspect-[423/410] w-[22.197rem] top-[3.96rem] -right-[7.073rem] -rotate-[75deg] sm:w-[42.3rem] sm:top-[3.742rem] sm:-right-[19.534rem] sm:-rotate-[75deg]',
        8: 'aspect-[367/387] w-[22rem] top-0.4 -left-4.4 sm:w-[36.7rem] sm:top-[0.961rem] sm:-left-[6rem]',
        10: 'aspect-[293/307] w-[20rem] top-1.6 -right-5.6 sm:w-[29.3rem] sm:top-[1.7rem] sm:-right-[8.5rem]',
      },
    },
  }
);

const cvaOverlay = cva([
  'ReviewPreviewCard-cvaOverlay',
  'hidden',
  'absolute z-10 top-0 left-0',
  'flex justify-center items-center',
  'w-full h-full',
  'opacity-0 invisible bg-cBlack16',
  'transition-all duration-300',
  'group-hover/ReviewPreviewCard:opacity-100 group-hover/ReviewPreviewCard:visible',
  'group-active/ReviewPreviewCard:opacity-100 group-active/ReviewPreviewCard:visible',
]);

const cvaButton = cva([
  'ReviewPreviewCard-cvaButton',
  'px-1.6 pt-1.1 pb-1.3 rounded-[1.6rem]',
  'bg-cWhite translate-y-[2rem]',
  'transition-transform duration-300',
  'group-hover/ReviewPreviewCard:translate-y-0 group-active/ReviewPreviewCard:translate-y-0',
]);
