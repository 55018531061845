'use client';
import {
  FC,
  ReactNode,
  useId,
  useRef,
} from 'react';
import Masonry, {
  ResponsiveMasonry,
} from 'react-responsive-masonry';
import { cva } from 'class-variance-authority';
import {
  animate,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import { useMedia } from 'react-use';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props {
  children?: ReactNode;
}

export const MasonryGrid: FC<Props> = ({
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();

  const { scrollYProgress } = useScroll({
    target: ref,
    axis: 'y',
    offset: ['start end', 'end start'],
  });

  const y = useTransform(
    scrollYProgress,
    [0, 1],
    [0, 80]
  );

  const isMobile = useMedia(
    '(max-width: 767px)',
    true
  );

  useMotionValueEvent(
    y,
    'change',
    (latestValue) => {
      animate(
        `[id="${id}"] .MasonryGrid-cvaMasonry > div:nth-child(odd)`,
        { y: isMobile ? 0 : -latestValue },
        { ease: 'linear', duration: 0 }
      );
      animate(
        `[id="${id}"] .MasonryGrid-cvaMasonry > div:nth-child(even)`,
        { y: isMobile ? 0 : latestValue },
        { ease: 'linear', duration: 0 }
      );
    }
  );

  return (
    <div id={id} ref={ref} className={cvaRoot()}>
      <ResponsiveMasonry
        className={twMergeExt(
          cvaResponsiveMasonry(),
          'hidden sm:flex'
        )}
        columnsCountBreakPoints={{
          1439: 5,
          767: 3,
          0: 2,
        }}
      >
        <Masonry
          className={cvaMasonry()}
          gutter={isMobile ? '0.8rem' : '1.2rem'}
        >
          {children}
        </Masonry>
      </ResponsiveMasonry>

      <div
        className={
          'grid grid-cols-2 gap-0.8 sm:hidden'
        }
      >
        {children}
      </div>
    </div>
  );
};

const cvaRoot = cva([
  'MasonryGrid-cvaRoot',
  'mt-3.2 sm:mt-7.2',
]);

const cvaResponsiveMasonry = cva([
  'MasonryGrid-cvaResponsiveMasonry',
]);

const cvaMasonry = cva([
  'MasonryGrid-cvaMasonry',
  'sm:[&>div:nth-child(odd)]:mt-8',
]);
