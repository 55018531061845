'use client';
import { AnaloguesCompare } from '@/features/analogues-compare';
import { IBloggerSet } from '@/shared/api/getMainPage/types';
import { BloggerCard } from '@/widgets/blogger-tips-section/ui/BloggerCard';
import { ProductPreviewCard } from '@/widgets/product-preview-card';
import { cva } from 'class-variance-authority';
import { FC } from 'react';

interface Props {
  items: IBloggerSet[];
}

export const BloggerTipsSection: FC<Props> = ({
  items,
}) => {
  return (
    <AnaloguesCompare
      className={cvaRoot()}
      title={'Советуют блогеры'}
      left={{
        contentContainerClassName: 'px-0',
        sliderControlsClassName:
          'flex justify-between w-full px-1.6 sm:px-3.2',
        heading: 'Блогер',
        slidesSlot: items.map((item, index) => (
          <BloggerCard
            key={index}
            image={item.preview_picture}
            title={item.name}
            description={item.preview_text}
            author={item.author}
          />
        )),
      }}
      right={{
        heading: 'Подборки',
        slidesSlot: () =>
          items.map((i) => {
            return i.products.flatMap(
              (p, idx) => (
                <ProductPreviewCard
                  {...p}
                  href={`${p.code}`}
                  key={idx}
                  className={cvaProductPreviewCardRight()}
                />
              )
            );
          }),
      }}
    />
  );
};

const cvaRoot = cva([
  'BloggerTipsSection-cvaRoot',
  'my-6.4 sm:my-[10.4rem]',
]);

const cvaProductPreviewCardRight = cva([
  'BloggerTipsSection-cvaProductPreviewCardRight',
  'h-full w-[23.2rem] sm:w-[34.55rem] md:w-auto',
  'rounded-[2rem] sm:rounded-[1.6rem]',
  'bg-cWhite',
]);
