import { Button } from '@/shared/ui/Button';
import { CountBadge } from '@/shared/ui/CountBadge';
import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import { FC } from 'react';
import PlusIcon from '~/icons/plus.svg';

interface Props
  extends VariantProps<typeof cvaImageWrapper> {
  className?: string;
  title: string;
  image?: string;
  badge?: number;
  onClick?: () => void;
}

export const Store: FC<Props> = ({
  className,
  title,
  image,
  badge,
  onClick,
  position,
}) => {
  return (
    <div
      className={twMergeExt(cvaRoot(), className)}
      onClick={onClick}
    >
      <div className={cvaWrapper()}>
        <div className={cvaTitle()}>
          {title}
          {badge ? (
            <CountBadge className={cvaBadge()}>
              {badge}
            </CountBadge>
          ) : null}
        </div>

        <Button
          className={cvaButton()}
          color={'darkGreyOnGrey'}
          size={'medium'}
          hoverEffect={'highlight'}
        >
          <PlusIcon
            className={'w-1.6 h-1.6 text-cBlack'}
          />
        </Button>
      </div>

      {image ? (
        <LazyImage
          wrapClassName={cvaImageWrapper({
            position,
          })}
          src={image}
          alt={title}
          fill
        />
      ) : null}
    </div>
  );
};

const cvaRoot = cva([
  'Store-cvaRoot group/Store',
  'relative',
  'py-1.6 sm:py-2.4 rounded-[2.1rem]',
  'cursor-pointer transition-colors duration-300',
  'before:absolute before:top-0 before:left-0 before:w-full before:h-0.1 before:bg-cBlack16 before:transition-colors before:duration-300',
  'last:after:absolute last:after:bottom-0 last:after:left-0 last:after:w-full last:after:h-0.1 last:after:bg-cBlack16 last:after:transition-colors last:after:duration-300',
  'first:before:bg-transparent last:after:bg-transparent',
  'md:[&:hover+.Store-cvaRoot]:before:bg-transparent',
  'md:hoctive:bg-cWhite md:hoctive:after:bg-transparent md:hoctive:before:bg-transparent',
]);

const cvaWrapper = cva([
  'Store-cvaWrapper',
  'relative',
  'flex justify-between items-center gap-2.4 md:justify-stretch',
  'md:group-hover/Store:translate-x-3.2 md:group-active:/Store:translate-x-3.2',
  'transition-transform duration-300',
]);

const cvaTitle = cva([
  'Store-cvaTitle',
  'relative',
  'font-secondary text-h3',
]);

const cvaBadge = cva([
  'Store-cvaBadge',
  'absolute -top-0.2 sm:-top-0.3 -right-1.6',
  'flex justify-center items-center',
  'w-1.6 h-1.6 sm:w-2.4 sm:h-2.4 rounded-full',
  'font-base bg-cMediumGreen text-cWhite text-bodyXXS sm:text-bodyS',
  'outline-cLightGrey group-hover/Store:outline-cWhite group-active/Store:outline-cWhite',
]);

const cvaButton = cva([
  'Store-cvaButton',
  'flex justify-center items-center',
  'grow-0 shrink-0 w-4 h-4 sm:w-5.6 sm:h-5.6 min-h-0',
  'transition-colors duration-300',
  'group-hover/Store:before:translate-y-0 group-hover/Store:before:scale-[1.2] group-hover/Store:before:duration-400 group-hover/Store:before:transition-transform group-hover/Store:before:ease-button-before]',
  'group-hover/Store:after:translate-y-0 group-hover/Store:after:[transition-duration:0.05s] group-hover/Store:after:[transition-delay:0.4s] group-hover/Store:after:ease-button-after',
]);

const cvaImageWrapper = cva(
  [
    'Store-cvaImageWrapper',
    'absolute z-[1] left-auto right-1.6',
    'hidden lg:block',
    'aspect-[30/21.2] w-[26rem] overflow-hidden rounded-[1.6rem] lg:w-[30rem]',
    'opacity-0 rotate-[4deg] invisible pointer-events-none',
    'group-hover/Store:translate-y-[0.54rem] group-hover/Store:translate-x-[10rem] group-hover/Store:rotate-[10deg] group-hover/Store:opacity-100 group-hover/Store:visible',
    'group-active/Store:translate-y-[0.54rem] group-active/Store:translate-x-[10rem] group-active/Store:rotate-[10deg] group-active/Store:opacity-100 group-active/Store:visible',
    'transition-all duration-300',
  ],
  {
    variants: {
      position: {
        top: '-top-0.8',
        bottom: '-top-9',
      },
    },
    defaultVariants: {
      position: 'top',
    },
  }
);
